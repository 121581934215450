/* index.css */

/* Reset some default styles */
body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Global styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
  color: #333;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  color: #007bff;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  cursor: pointer;
}

/* Utility classes */
.mt-5 {
  margin-top: 3rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.container {
  padding: 20px;
}
