/* App.css */

.App {
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  color: #333;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

input, select {
  padding: 0px;
  font-size: 1rem;
  margin-bottom: 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.card {
  margin: 20px 0;
}

.card h4 {
  margin-bottom: 15px;
}

.card-body {
  padding: 20px;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.table-bordered {
  border-collapse: collapse;
}

.table-bordered td,
.table-bordered th {
  border: 2px solid #dee2e6; /* Customize border thickness and color */
}
